import { useEffect } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Button, ButtonProps, Flex, useMediaQuery, useToken } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { ACCESS_TOKEN, ORIGIN_PATH } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { getServerAzureLogoutUrl } from 'composable/helpers/utils/use-user-utils';
import { ButtonLabel, SideMenuItem } from 'composable/types';
import routes from 'helpers/constants/routes';
import { Reference, getReferenceTarget } from 'helpers/reference';

type ButtonLinksProps = {
  links?: SideMenuItem[];
};

const BUTTON_LABELS = [ButtonLabel.LOG_IN_TO_ORDER?.toLowerCase(), ButtonLabel.LOG_OUT?.toLowerCase()];

export const ButtonLinks = ({ links }: ButtonLinksProps) => {
  const [isMobile] = useMediaQuery(`(max-width: (max-width: 1026px)`);
  const neutral700 = useToken('colors', 'neutral.700');
  const router = useRouter();
  const [originPath, setOriginPath] = useLocalStorage(ORIGIN_PATH, '');
  const [accessToken] = useLocalStorage(ACCESS_TOKEN, '');
  let originUrl = router.asPath;
  const { azureRedirectUrl = '', logout } = useGlobal().useUserGlobal;
  const isErrorPage = router.asPath.includes(routes.PUBLIC_ERROR_PAGE);
  const loginError = (Array.isArray(router.query?.login) ? router.query?.login[0] : router.query?.login) || '';

  // Set origin for public PDP as regular PDP in case of user login for redirect.
  if (router.asPath.includes(routes.PUBLIC_PDP)) {
    originUrl = originUrl.replace(routes.PUBLIC_PDP, routes.PDP);
  }

  useEffect(() => {
    if (
      router.asPath.includes(routes.PUBLIC_PDP) &&
      originPath !== originUrl &&
      !accessToken &&
      !router.asPath.includes(routes.PUBLIC_ERROR_PAGE)
    ) {
      setOriginPath(originUrl);
    }
  }, [originUrl, router.asPath, accessToken, originPath]);

  if (!links) return null;
  return (
    <Flex gap={5} w="100%" alignItems="center" flexDir={'column'} justifyContent={'flex-start'}>
      {links.map((link) => {
        const href = getReferenceTarget(link.link as Reference);
        const isExternal = link.link.openInNewWindow;
        const externalLinkProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {};
        const buttonStyle: ButtonProps = {
          w: { base: 'fit-content', xl: '166px' },
        };

        if (link?.label?.toLowerCase() === ButtonLabel.JOIN_TODAY?.toLowerCase()) {
          return (
            <NextLink key={`public_left_side_menu_${link?.label}`} href={href} passHref>
              <Button
                as="a"
                size={isMobile ? 'ds-md' : 'ds-lg'}
                variant="ds-filled"
                {...buttonStyle}
                {...externalLinkProps}
              >
                {link?.label}
              </Button>
            </NextLink>
          );
        }

        if (BUTTON_LABELS.includes(link?.label?.toLowerCase())) {
          const isLogout = link?.label.toLowerCase() === ButtonLabel.LOG_OUT?.toLowerCase();
          const loginHref = isErrorPage ? getServerAzureLogoutUrl(loginError) : azureRedirectUrl;
          const variant = isLogout ? 'ds-filled' : 'ds-ghost';
          return (
            <NextLink
              key={`public_left_side_menu_${link?.label}`}
              href={isLogout ? getServerAzureLogoutUrl('') : loginHref}
              passHref
            >
              <Button
                bgColor={neutral700}
                size={isMobile ? 'ds-md' : 'ds-lg'}
                variant={variant}
                {...buttonStyle}
                {...externalLinkProps}
                {...(isLogout && { textDecoration: 'underline', onClick: () => logout(true) })}
              >
                {link?.label}
              </Button>
            </NextLink>
          );
        }
      })}
    </Flex>
  );
};
