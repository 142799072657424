import routes from 'helpers/constants/routes';
import { AccordionLink } from 'frontastic/tastics/composable/mega-menu-three-levels-2';
import { excludedLabels, LeftSideMenuLabels } from '.';

export const isProductPage = (path: string) => /^\/product\/\w+/.test(path);
export const isCategoryPage = (path: string) => /^\/category\/\w+/.test(path);
export const isSearchPage = (path: string) => /^\/search(\?.*)?$/.test(path);
export const isOrderGuidePage = (path: string) => /^\/order-guide(\?.*)?$/.test(path);
export const isHomePage = (path: string) => /^\/\?code=[^&]+(&.*)?$/.test(path);
export const isCatalogSelectedCb = (path: string) => isProductPage(path) || isCategoryPage(path) || isSearchPage(path);

export const isLabelExcluded = (label: string): boolean => excludedLabels.includes(label as LeftSideMenuLabels);
export const isAccountLabel = (label: string): boolean => LeftSideMenuLabels.Accounts === label;
export const selectedItemCb = (path: string) => {
  if (isCatalogSelectedCb(path)) {
    return routes.CATALOG;
  }
  return isHomePage(path) ? routes.HOME : path;
};

export const handleGetHref = (child) => {
  const href = child.link?.type
    ? child.link.type === 'link'
      ? `${child.type && child.type !== 'default' ? `${child.type}:` : ''}${child.link?.link}`
      : child.link.type === 'page-folder'
      ? `${child.type && child.type !== 'default' ? `${child.type}:` : ''}${child.link.pageFolder?._url}`
      : ''
    : '';

  return href;
};

export const checkLinkType = (child: AccordionLink['children'][0], href: string) => {
  if (child.type === 'tel') {
    return `tel:${href}`;
  }

  if (child.type === 'mailto') {
    return `mailto:${href}`;
  }

  return href;
};
