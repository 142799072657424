import dynamic from 'next/dynamic';
import { FEATURE_FLAG_PUBLIC_PDP } from 'composable/components/general';

const AccountsContainerTastic = dynamic(() => import('frontastic/tastics/composable/accounts-balance-container'), {
  ssr: false,
});
const CuratedProductListTastic = dynamic(() => import('frontastic/tastics/composable/curated-product-list'), {
  ssr: false,
});
const LogoTileTastic = dynamic(() => import('frontastic/tastics/composable/logo-tile'), { ssr: false });
const PublicFooterTastic = dynamic(() => import('frontastic/tastics/composable/public-footer'), { ssr: false });
const PublicHeaderDesktopTastic = dynamic(() => import('frontastic/tastics/composable/public-header-desktop'), {
  ssr: false,
});

const PublicNotFoundTastic = dynamic(() => import('frontastic/tastics/composable/public-not-found'), { ssr: false });
const TrendingProductsList = dynamic(() => import('frontastic/tastics/composable/trending-products-list'), {
  ssr: false,
});
const AccountsTastic = dynamic(() => import('./composable/accounts'), { ssr: false });
const ArticleCardListTastic = dynamic(() => import('./composable/article-card-list'), { ssr: false });
const ArticleCardTastic = dynamic(() => import('./composable/article-card'), { ssr: false });
const BannerSplitTastic = dynamic(() => import('./composable/banner-split'), { ssr: false });
const BannerTextOnlyTastic = dynamic(() => import('./composable/banner-text-only'), { ssr: false });
const CartTastic = dynamic(() => import('./composable/cart'), { ssr: false });
const CategoryBannerTastic = dynamic(() => import('./composable/category-banner'), { ssr: false });
const CategoryTileListTastic = dynamic(() => import('./composable/category-tile-list'), { ssr: false });
const CheckoutTastic = dynamic(() => import('./composable/checkout'), { ssr: false });
const CheckoutSuccessTastic = dynamic(() => import('./composable/checkout-success'), { ssr: false });
const CoverCardListTastic = dynamic(() => import('./composable/cover-card-list'), { ssr: false });
const CoverCardTastic = dynamic(() => import('./composable/cover-card'), { ssr: false });
const EditOrderTastic = dynamic(() => import('./composable/edit-order'), { ssr: false });
const OrderPrintBackTastic = dynamic(() => import('./composable/order-printback'), { ssr: false });
const FooterTastic = dynamic(() => import('./composable/footer'), { ssr: false });
const HeaderDesktopTastic = dynamic(() => import('./composable/header-desktop'), { ssr: false });
const HeroTastic = dynamic(() => import('./composable/hero'), { ssr: false });
const HeroBannerTastic = dynamic(() => import('./composable/hero-banner/hero-banner'), { ssr: false });
const HomeContentTastic = dynamic(() => import('./composable/home-content'), { ssr: false });
const HomePageButtonsSoloTastic = dynamic(() => import('./composable/home-page-buttons-solo'), { ssr: false });
const LeftSideMenuTastic = dynamic(() => import('./composable/left-side-menu'), { ssr: false });
const LogoTileStripTastic = dynamic(() => import('./composable/logo-tile-strip'), { ssr: false });
const MegaMenuThreeLevels = dynamic(() => import('./composable/mega-menu-three-levels'), { ssr: false });
const MegaMenuThreeLevelsTasticTwo = dynamic(() => import('./composable/mega-menu-three-levels-2'), { ssr: false });
const NotFoundTastic = dynamic(() => import('./composable/not-found'), { ssr: false });
const OrderDetailsTastic = dynamic(() => import('./composable/order-details'), { ssr: false });
const OrderEditCheckoutTastic = dynamic(() => import('./composable/order-edit-checkout'), { ssr: false });
const OrderGuideTastic = dynamic(() => import('./composable/order-guide'), { ssr: false });
const OrderGuideEditTastic = dynamic(() => import('./composable/order-guide-edit'), { ssr: false });
const OrderGuideManagementTastic = dynamic(() => import('./composable/order-guide-management'), { ssr: false });
const OrdersTastic = dynamic(() => import('./composable/orders'), { ssr: false });
const PLPTastic = dynamic(() => import('./composable/plp'), { ssr: false });
const ProductDetailPage = dynamic(() => import('./composable/product-detail-page'), { ssr: false });
const PromoBannerTastic = dynamic(() => import('./composable/promo-banner'), { ssr: false });
const PromoSliderTastic = dynamic(() => import('./composable/promo-slider'), { ssr: false });
const PublicProductDetailPage = dynamic(
  () => import('./composable/public-product-detail-page/PublicProductDetailTastic'),
  { ssr: false },
);
const RecentOrdersContainerTastic = dynamic(() => import('./composable/recent-orders-container'), { ssr: false });
const RichTextTastic = dynamic(() => import('./composable/rich-text'), { ssr: false });
const TextCardTastic = dynamic(() => import('./composable/text-card'), { ssr: false });
const PageTitleTastic = dynamic(() => import('./orium/page-title'), { ssr: false });
const PublicErrorDisplayTastic = dynamic(() => import('./composable/public-error-display'), { ssr: false });
const SuperUserTastic = dynamic(() => import('./composable/super-user'), { ssr: false });
const SuperUserAccountNumberTastic = dynamic(() => import('./composable/super-user-account-number'), { ssr: false });

export const tastics = {
  'composable/article-card-list': ArticleCardListTastic,
  'composable/article-card': ArticleCardTastic,
  'composable/hero-banner': HeroBannerTastic,
  'composable/banner-split': BannerSplitTastic,
  'composable/cover-card-list': CoverCardListTastic,
  'composable/cover-card': CoverCardTastic,
  'composable/text-card': TextCardTastic,
  'composable/banner-text-only': BannerTextOnlyTastic,
  'composable/rich-text': RichTextTastic,
  'composable/header-desktop': HeaderDesktopTastic,
  'composable/public-header-desktop': FEATURE_FLAG_PUBLIC_PDP ? PublicHeaderDesktopTastic : HeaderDesktopTastic,
  'composable/mega-menu-three-levels': MegaMenuThreeLevels,
  'composable/public-mega-menu-three-levels': MegaMenuThreeLevelsTasticTwo,
  'composable/mega-menu-three-levels-2': MegaMenuThreeLevelsTasticTwo,
  'composable/footer': FooterTastic,
  'composable/public-footer': FEATURE_FLAG_PUBLIC_PDP ? PublicFooterTastic : FooterTastic,
  'composable/home-page-buttons-solo': HomePageButtonsSoloTastic,
  'composable/hero': HeroTastic,
  'composable/orders': OrdersTastic,
  'composable/order': OrderDetailsTastic,
  'composable/edit-order': EditOrderTastic,
  'composable/order-printback': OrderPrintBackTastic,
  'composable/order-edit-checkout': OrderEditCheckoutTastic,
  'composable/home-content': HomeContentTastic,
  'composable/home-content-dev': HomeContentTastic,
  'composable/promo-banner': PromoBannerTastic,
  'composable/plp': PLPTastic,
  'composable/cart': CartTastic,
  'composable/checkout': CheckoutTastic,
  'composable/checkout-success': CheckoutSuccessTastic,
  'composable/product-detail-page': ProductDetailPage,
  'composable/public-product-detail-page': FEATURE_FLAG_PUBLIC_PDP ? PublicProductDetailPage : ProductDetailPage,
  'composable/not-found': NotFoundTastic,
  'composable/public-not-found': PublicNotFoundTastic,
  'composable/category-banner': CategoryBannerTastic,
  'composable/category-banner-group': CategoryTileListTastic,
  'composable/left-side-menu': LeftSideMenuTastic,
  'composable/order-guide': OrderGuideTastic,
  'composable/order-guide-management': OrderGuideManagementTastic,
  'composable/dynamic-order-guide': OrderGuideEditTastic,
  'orium/page-title': PageTitleTastic,
  'composable/accounts': AccountsTastic,
  'composable/promo-slider': PromoSliderTastic,
  'composable/recent-orders-container': RecentOrdersContainerTastic,
  'composable/logo-tile': LogoTileTastic,
  'composable/logo-tile-strip': LogoTileStripTastic,
  'composable/accounts-balance-container': AccountsContainerTastic,
  'composable/trending-products-list': TrendingProductsList,
  'composable/curated-product-list': CuratedProductListTastic,
  'composable/public-error-display': PublicErrorDisplayTastic,
  'composable/super-user-page': SuperUserTastic,
  'composable/super-user-page-account-number': SuperUserAccountNumberTastic,
  default: NotFoundTastic,
};
