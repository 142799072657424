import { SHOP_PURCHASE_HISTORY, TEN_KEY, useLocalStorageOrderGuide } from 'composable/components/order-guide/helpers';
import { LeftSideMenuLabels } from '..';

export const useOrderGuideChildItems = (label: string) => {
  const {
    ogLocalStorageValues: { selectedOrderGuide },
  } = useLocalStorageOrderGuide();

  const isPurchaseHistorySelected =
    selectedOrderGuide === SHOP_PURCHASE_HISTORY && label === LeftSideMenuLabels.PurchaseHistory;

  const ogChildItems = [LeftSideMenuLabels.PurchaseHistory, LeftSideMenuLabels.TenKey] as string[];

  const is10KeySelected = selectedOrderGuide === TEN_KEY && label === LeftSideMenuLabels.TenKey;

  const ogChildItemSelected = isPurchaseHistorySelected || is10KeySelected;

  const ogItemSwitchMap = {
    [LeftSideMenuLabels.PurchaseHistory]: SHOP_PURCHASE_HISTORY,
    [LeftSideMenuLabels.TenKey]: TEN_KEY,
  };

  return { ogChildItems, ogChildItemSelected, ogItemSwitchMap };
};
