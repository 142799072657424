import { useEffect, useState } from 'react';
import { SUPER_USER_BANNER_ID } from '../constants';
import { useIsBrowser } from 'composable/helpers/hooks';
import { useGlobal } from 'components/globalProvider';

export const useGetSuperUserBannerSize = () => {
  const {
    state: { loggedAsSuperUser = false },
  } = useGlobal().useUserGlobal;
  const [height, setHeight] = useState(0);
  const isBrowser = useIsBrowser();

  useEffect(() => {
    const element = document.getElementById(SUPER_USER_BANNER_ID);
    if (!element || !isBrowser || !loggedAsSuperUser) {
      return;
    }

    const updateHeight = () => {
      setHeight(element.offsetHeight);
    };

    updateHeight();

    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isBrowser, loggedAsSuperUser]);

  return height;
};
