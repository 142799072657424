import React, { useMemo } from 'react';
import NextLink from 'next/link';
import { Img, Text, Icon, ListItem, ListItemProps, Link as ChakraLink } from '@chakra-ui/react';
import {
  LeftSideMenuLabels,
  excludedLabels,
  excludedLabesOpenNewWindow,
} from 'composable/components/cms-components/left-side-menu';
import { SideMenuItem } from 'composable/types';
import { useFormat } from 'helpers/hooks';
import { Minus, Plus } from 'phosphor-react';
import { useRouter } from 'next/router';
import { isAccountLabel, isCatalogSelectedCb, isHomePage, isLabelExcluded, selectedItemCb } from './utils';
import routes from 'helpers/constants/routes';
import { FEATURE_FLAG_SIDEBAR_CHANGES } from 'composable/components/general';

interface MenuItemProps {
  item: SideMenuItem;
  locale: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  isAccountOpened: boolean;
  listItemProps?: ListItemProps;
  isMobileDrawer?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  item,
  locale,
  onClick,
  isAccountOpened,
  listItemProps,
  isMobileDrawer = false,
}) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const routePath = item?.link.type === 'link' ? item.link?.link : item.link?.pageFolder?._url;

  const handleListItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
    if (item.children && item.label !== LeftSideMenuLabels.Catalog) {
      onClick(e as unknown as React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>);
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    onClick(e as unknown as React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>);
  };

  const shouldApplyBorder = useMemo(() => {
    const path = router.asPath;
    const itemTextToHandle = item.link.type === 'link' ? item.link.link : item.link.pageFolder?._url;
    const selectedItem = selectedItemCb(path);
    let selected = selectedItem === itemTextToHandle;

    const isExcluded = isLabelExcluded(item.label);
    const isAccount = isAccountLabel(item.label);
    const isCatalogSelected = isCatalogSelectedCb(path);

    if (isCatalogSelected && item.label === LeftSideMenuLabels.Catalog) {
      selected = true;
    }

    const shouldHighlightAccount =
      (isAccount && selectedItem === routes.ORDER_GUIDE_MANAGEMENT) || (isAccount && isAccountOpened);
    if (shouldHighlightAccount) {
      return true;
    }

    if (isHomePage(routePath) && item.label === LeftSideMenuLabels.Home) {
      selected = true;
    }

    return selected && !isExcluded;
  }, [item, isAccountOpened, router.asPath]);

  const excludedItems = [LeftSideMenuLabels.MyShamrock, LeftSideMenuLabels.ShamrockORDERS];
  const shouldExcludeItemSidebarUpdates =
    FEATURE_FLAG_SIDEBAR_CHANGES && excludedItems.includes(item.label as LeftSideMenuLabels);

  const RenderContent = () => (
    <>
      {item.icon && (
        <Img
          src={item.icon.file || item?.icon?.media?.file}
          width={6}
          height={6}
          m="auto"
          mr="2"
          ml="0"
          filter={shouldApplyBorder ? 'brightness(100)' : 'none'}
          _groupHover={{ filter: 'brightness(100)' }}
          alt={`${item.icon?.title?.[locale]} icon`}
        />
      )}
      <Text
        fontSize={excludedLabesOpenNewWindow.includes(item.label as LeftSideMenuLabels) ? 'sm' : 'md'}
        fontWeight="bold"
        lineHeight={1.2}
        margin="auto 0"
      >
        {item.label}
      </Text>
      {item?.children?.length > 0 &&
        item.label !== LeftSideMenuLabels.Catalog &&
        item.label !== LeftSideMenuLabels.OrderGuides && (
          <Icon
            width={4}
            height={4}
            ml={1}
            my="auto"
            as={isAccountOpened ? Minus : Plus}
            color={shouldApplyBorder ? 'white' : 'neutral.200'}
            _groupHover={{ color: 'primary.500' }}
            aria-label={formatMessage({ id: isAccountOpened ? 'ariaLabel.minus' : 'ariaLabel.plus' })}
          />
        )}
      {(item.link.openInNewWindow || (item?.link?.type === 'link' && !item.icon)) && (
        <Img
          src="/arrowUpRight.svg"
          width={3}
          height={3}
          m="auto"
          mr="0"
          ml="2"
          filter={shouldApplyBorder ? 'brightness(100)' : 'none'}
          _groupHover={{ filter: 'brightness(100)' }}
          alt={formatMessage({ id: 'navigation.link.externalAltText' })}
        />
      )}
    </>
  );

  return (
    <ListItem
      w="100%"
      display={shouldExcludeItemSidebarUpdates ? 'none' : 'flex'}
      cursor="pointer"
      alignItems="center"
      color="neutral.200"
      h={excludedLabesOpenNewWindow.includes(item.label as LeftSideMenuLabels) ? 10 : { base: 14, lg: 16 }}
      px={{ base: 8, lg: 10 }}
      py={{ base: 2, lg: 2.5 }}
      justifyContent="flex-start"
      borderRight={shouldApplyBorder ? '4px' : 0}
      backgroundColor={
        item.label === LeftSideMenuLabels.OrderGuides && isMobileDrawer
          ? 'rgba(31, 31, 35, 0.50) !important'
          : 'transparent'
      }
      bg={shouldApplyBorder ? 'rgba(31, 31, 35)' : 'transparent'}
      style={{ borderImage: 'linear-gradient(to bottom, #12875E 0%, #2DA343 100%) 1 stretch' }}
      _hover={{
        boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.12)',
        bg: {
          base: !excludedLabels.includes(item.label as LeftSideMenuLabels) ? 'rgba(31, 31, 35)' : 'transparent',
          lg: 'greenGradient',
        },
        color: 'white',
        textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
      }}
      _focusWithin={{
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '-2px',
        boxShadow: 'none',
        borderRadius: '2px',
      }}
      onClick={handleListItemClick}
      {...listItemProps}
    >
      <NextLink href={routePath || '/'} passHref>
        <ChakraLink
          display="flex"
          w="full"
          h="full"
          sx={{ textDecoration: 'none !important' }}
          onClick={handleLinkClick}
        >
          <RenderContent />
        </ChakraLink>
      </NextLink>
    </ListItem>
  );
};
