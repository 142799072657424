import React from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Icon, ListItem, Link as ChakraLink } from '@chakra-ui/react';
import { LeftSideMenuLabels, excludedShopCategoriesLabel } from 'composable/components/cms-components/left-side-menu';
import { useComposable } from 'composable/components/composable-provider';
import { useOrderGuideFiltersContext } from 'composable/components/order-guide/components/context/order.guide.filters.context';
import { SideMenuItemChild } from 'composable/types';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { CaretRight } from 'phosphor-react';
import { useOrderGuideChildItems } from './hooks/use-order-guide-child-items';
import { isOrderGuidePage } from './utils';

interface ChildrenMenuItemProps {
  item: SideMenuItemChild;
  onClick: () => void;
  isSelected: (path: string) => boolean;
}

export const ChildrenMenuItem: React.FC<ChildrenMenuItemProps> = ({ item, isSelected, onClick }) => {
  const { handleSelectOrderGuide } = useOrderGuideFiltersContext();
  const { formatMessage } = useFormat({ name: 'common' });
  const { ogChildItems, ogChildItemSelected, ogItemSwitchMap } = useOrderGuideChildItems(item?.label);
  const { asPath: path, push } = useRouter();
  const isOrderGuideSelected = isOrderGuidePage(path);
  const { megaDrawer } = useComposable();

  const routePath = item.link.type === 'link' ? item.link.link : item.link.pageFolder?._url;
  const backgroundColor =
    item.label === LeftSideMenuLabels.ShopCategories && routePath.includes(routes.CATALOG)
      ? 'rgba(31, 31, 35, 0.50)'
      : (isSelected(item.link.link) && !excludedShopCategoriesLabel.includes(item.label as LeftSideMenuLabels)) ||
        (ogChildItemSelected && isOrderGuideSelected)
      ? 'neutral.900'
      : 'rgba(31, 31, 35, 0.50)';

  const handleKeyDown = (e: React.KeyboardEvent, label?: string) => {
    if (e.key === 'Enter') {
      if (ogChildItems.includes(label)) {
        const ogItemToSwitchTo = ogItemSwitchMap[label];
        handleSelectOrderGuide({ target: { value: ogItemToSwitchTo } } as React.ChangeEvent<HTMLSelectElement>);
      }
      if (item.label === LeftSideMenuLabels.ShopCategories) {
        e.preventDefault();
        onClick();
      } else {
        e.preventDefault();
        document.getElementById(`link-${item.label}`)?.click();
      }
    }
  };

  const handleItemClick = (label: string) => {
    if (ogChildItems.includes(label)) {
      if (!isOrderGuideSelected) {
        push(`${routes.ORDER_GUIDE}`);
      }

      const ogItemToSwitchTo = ogItemSwitchMap[label];
      handleSelectOrderGuide({ target: { value: ogItemToSwitchTo } } as React.ChangeEvent<HTMLSelectElement>);
      megaDrawer.onClose();
    } else {
      onClick();
    }
  };

  return (
    <ListItem
      px={10}
      py={2.5}
      h={item.label === LeftSideMenuLabels.ShopCategories ? '12' : '100%'}
      tabIndex={0}
      display="flex"
      onClick={() => handleItemClick(item?.label)}
      onKeyDown={(e) => handleKeyDown(e, item?.label)}
      alignItems="center"
      color="neutral.200"
      backgroundColor={backgroundColor}
      fontWeight={LeftSideMenuLabels.ShopCategories ? 700 : {}}
      fontSize={LeftSideMenuLabels.ShopCategories ? 'sm' : {}}
      _hover={{
        color: item.label === LeftSideMenuLabels.ShopCategories ? 'primary.500' : 'white',
        cursor: 'pointer',
        bg: { md: item.label === LeftSideMenuLabels.ShopCategories ? backgroundColor : 'greenGradient' },
      }}
      style={{ borderImage: 'linear-gradient(to bottom, #12875E 0%, #2DA343 100%) 1 stretch' }}
      borderRight={
        (isSelected(item.link.link) && !excludedShopCategoriesLabel.includes(item.label as LeftSideMenuLabels)) ||
        (ogChildItemSelected && isOrderGuideSelected)
          ? '4px'
          : 0
      }
      _focusVisible={{
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '-2px',
        boxShadow: 'none',
        borderRadius: '2px',
      }}
    >
      {item?.label !== LeftSideMenuLabels.ShopCategories && !ogChildItems.includes(item.label) ? (
        <NextLink href={routePath} passHref>
          <ChakraLink
            as="a"
            id={`link-${item.label}`}
            display="flex"
            w="full"
            h="full"
            alignItems="center"
            style={{ textDecoration: 'none' }}
          >
            {item.label}
          </ChakraLink>
        </NextLink>
      ) : (
        <>
          {item.label}
          {item.label === LeftSideMenuLabels.ShopCategories && (
            <Icon ml={2} w={4} h={4} as={CaretRight} aria-label={formatMessage({ id: 'caret.right.icon.label' })} />
          )}
        </>
      )}
    </ListItem>
  );
};
