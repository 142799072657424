import {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useOrderListPage } from 'components/orderListProvider';
import { OrderGuideFilteredTags } from 'composable/components/order-guide/utils/filterOrderGuideByTags';
import { OGPH_DEFAULT_NUMBER_OF_WEEKS, SHOP_PURCHASE_HISTORY, useLocalStorageOrderGuide } from '../../helpers';

type OrderGuideContextProps = {
  handleSelectOrderGuide: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  filteredTagOptions: OrderGuideFilteredTags[];
  handleFilteredTagOptions: (option: OrderGuideFilteredTags | OrderGuideFilteredTags[]) => void;
  resetFilteredTagOptions: () => void;
  setFilteredTagOptions: Dispatch<SetStateAction<OrderGuideFilteredTags[]>>;
};

const OrderGuideFiltersContext = createContext<undefined | OrderGuideContextProps>(undefined);

export const OrderGuideFiltersProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { setValue, ogLocalStorageValues, setAllValues } = useLocalStorageOrderGuide();
  const { orderGuideLoadingState } = useOrderListPage();

  const [filteredTagOptions, setFilteredTagOptions] = useState<OrderGuideFilteredTags[]>([]);

  const handleSelectOrderGuide = (event: React.ChangeEvent<HTMLSelectElement>) => {
    orderGuideLoadingState.current = true;
    if (
      (event.target.value !== SHOP_PURCHASE_HISTORY &&
        ogLocalStorageValues.selectedOrderGuide === SHOP_PURCHASE_HISTORY) ||
      (event.target.value === SHOP_PURCHASE_HISTORY &&
        ogLocalStorageValues.selectedOrderGuide !== SHOP_PURCHASE_HISTORY)
    ) {
      // Reset ogLocalStorageValues
      // - when user switches back to an Order Guide from SHOP_PURCHASE_HISTORY
      // Or
      // - when user switches back to SHOP_PURCHASE_HISTORY from any Order Guide
      setAllValues({
        scrollPosition: 0,
        searchTerm: '',
        selectedOrderGuide: event.target.value,
        selectedSortOptionOrderGuide: {
          selectedSort: 'default',
          onClickedSort: 'default',
        },
        purchaseHistoryNumberOfWeeks: OGPH_DEFAULT_NUMBER_OF_WEEKS,
        virtualizedScrollPosition: 0,
      });
    } else if (event.target.value !== '-') {
      setValue('selectedOrderGuide', event.target.value);
    }
    resetFilteredTagOptions();
  };

  const handleFilteredTagOptions = useCallback((option: OrderGuideFilteredTags | OrderGuideFilteredTags[]) => {
    setFilteredTagOptions((prev) => {
      if (Array.isArray(option)) {
        // If option is an array of strings
        let updatedOptions = [...prev];

        option.forEach((opt) => {
          if (updatedOptions.includes(opt)) {
            updatedOptions = updatedOptions.filter((item) => item !== opt);
          } else {
            updatedOptions = updatedOptions.concat(opt);
          }
        });
        return updatedOptions;
      }

      const item = prev.includes(option);
      if (item) {
        return prev.filter((item) => item !== option);
      }
      return prev.concat(option);
    });
  }, []);

  const resetFilteredTagOptions = useCallback(() => {
    setFilteredTagOptions([]);
  }, []);

  return (
    <OrderGuideFiltersContext.Provider
      value={{
        handleSelectOrderGuide,
        filteredTagOptions,
        handleFilteredTagOptions,
        resetFilteredTagOptions,
        setFilteredTagOptions,
      }}
    >
      {children}
    </OrderGuideFiltersContext.Provider>
  );
};

export const useOrderGuideFiltersContext = () => {
  const context = useContext(OrderGuideFiltersContext);
  if (!context) {
    throw new Error('useOrderGuideFiltersContext must be used within a useOrderGuideFiltersProvider');
  }
  return context;
};
