import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import {
  Accordion,
  Box,
  Button,
  Collapse,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link,
  List,
} from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { useComposable } from 'composable';
import { ShoppingCategoriesOpenProps } from 'composable/components/checkout/context';
import { ChildrenMenuItem } from 'composable/components/cms-components/left-side-menu/children-menu-item';
import { MenuItem } from 'composable/components/cms-components/left-side-menu/menu-item';
import { Nav } from 'composable/components/cms-components/left-side-menu/nav';
import { ShopCategories } from 'composable/components/cms-components/shop-categories-left-side-menu';
import {
  FEATURE_FLAG_DISPLAY_SHOP_CATEGORIES_SIDE_MENU_OPTION,
  FEATURE_FLAG_HEADER_CHANGES,
  FEATURE_FLAG_SIDEBAR_CHANGES,
} from 'composable/components/general';
import { PayBillButton } from 'composable/components/pay-bill-button';
import { usePropsMenu } from 'composable/helpers/hooks/usePropsMenu';
import { SideMenuItem } from 'composable/types';
import { ButtonLinks } from './button-links';
import { isOrderGuidePage, selectedItemCb } from './utils';
import NextLink from 'next/link';
import { Logo } from 'composable/components/logo';
import { SideBarAccordionItem } from './sidebar-accordion-item';
import { useHandleHeightShift } from './hooks/use-handle-height-shift';
import { useFormat } from 'helpers/hooks';
import { isPublicSlug } from 'helpers/slugHelpers';
import { AccordionLink } from 'frontastic/tastics/composable/mega-menu-three-levels-2';
import { useOrderGuideFiltersContext } from 'composable/components/order-guide/components/context/order.guide.filters.context';

export interface LeftSideMenuProps {
  links: SideMenuItem[];
  accordionLinks?: AccordionLink[];
}

//Constants
//TODO - Move to constants file and check references in other components
export const leftSideMenuWidth = '251px';
export enum LeftSideMenuLabels {
  Home = 'Home',
  OrderGuides = 'Order Guides',
  Catalog = 'Catalog',
  Orders = 'Orders',
  Accounts = 'Accounts',
  PayBill = 'Pay Bill',
  MyShamrock = 'MyShamrock',
  ShamrockORDERS = 'ShamrockORDERS',
  ShopCategories = 'Shop Categories',
  PurchaseHistory = 'Purchase History',
  TenKey = '10-Key',
}
export const excludedLabesOpenNewWindow = [
  LeftSideMenuLabels.ShamrockORDERS,
  LeftSideMenuLabels.MyShamrock,
  LeftSideMenuLabels.PayBill,
];
export const excludedShopCategoriesLabel = [LeftSideMenuLabels.ShopCategories];
export const excludedLabels = [...excludedLabesOpenNewWindow, ...excludedShopCategoriesLabel];
// Buttons that are displayed in public pages

export const LeftSideMenu = ({ links, accordionLinks }: LeftSideMenuProps) => {
  const { shoppingCategoriesOpen, closeShoppingCategories, openShoppingCategories } = useGlobal().useCheckoutGlobal;
  const {
    state: { primaryOrderGuide },
  } = useGlobal().useOrderGuideListGlobal;

  const { handleSelectOrderGuide } = useOrderGuideFiltersContext();
  const { logout } = useGlobal().useUserGlobal;

  const { formatMessage } = useFormat({ name: 'common' });
  const listRef = useRef(null);
  const { difference } = useHandleHeightShift(listRef);

  const router = useRouter();
  const { asPath: path } = useRouter();
  const { megaDrawer } = useComposable();

  const { openItems, setOpenItems, buttons, finalLinks } = usePropsMenu(links);

  // Set selected item based on URL
  const selectedItem = useMemo(() => selectedItemCb(router.asPath), [router.asPath]);

  const toggleOpen = useCallback((label: string) => {
    setOpenItems((prev) => ({ ...prev, [label]: !prev[label] }));
  }, []);

  const isSelected = useCallback(
    (path: string) => {
      return path === selectedItem;
    },
    [selectedItem],
  );

  const handleLogout = async () => {
    await logout(true);
  };

  const slug = router.asPath;
  const isPublicPage = isPublicSlug(slug);

  const showAccordionLinks = FEATURE_FLAG_SIDEBAR_CHANGES && accordionLinks && accordionLinks.length > 0;

  return (
    <>
      {FEATURE_FLAG_DISPLAY_SHOP_CATEGORIES_SIDE_MENU_OPTION && (
        <Drawer
          placement="left"
          onClose={closeShoppingCategories}
          isOpen={shoppingCategoriesOpen === ShoppingCategoriesOpenProps.LEFT_SIDE}
        >
          <DrawerOverlay css={{ background: 'rgba(0, 0, 0, .7)' }} />
          <DrawerContent
            minW={{ base: '339px', lg: '400px' }}
            maxW={{ base: '339px', lg: '400px' }}
            style={ShopCategories.DrawerStyle}
            {...ShopCategories.DrawerCSS}
          >
            <ShopCategories />
          </DrawerContent>
        </Drawer>
      )}

      <Nav leftSideMenuWidth={leftSideMenuWidth}>
        {/* Shamrock Logo */}
        {FEATURE_FLAG_HEADER_CHANGES && (
          <Flex px={10} paddingTop={5} paddingBottom={12}>
            <NextLink href="/" passHref>
              <Link
                tabIndex={0}
                pointerEvents="all"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    router.push('/');
                  }
                }}
                _focusVisible={{
                  outlineColor: 'violet.400',
                  outlineWidth: '2px',
                  outlineStyle: 'solid',
                  borderRadius: '4px',
                  boxShadow: 'none',
                }}
              >
                <Logo
                  isHideOnPage={false}
                  imageProps={{
                    transform: 'translateY(0px)',
                    w: { base: '64px', xl: '94px' },
                    h: { base: '29px', xl: '43px' },
                  }}
                />
              </Link>
            </NextLink>
          </Flex>
        )}

        <ButtonLinks links={buttons} />
        <List ref={listRef}>
          {finalLinks.map((item) => {
            switch (item.menuType) {
              case 'payBillButton':
                return <PayBillButton title={item.label} key={`payBillButton_${item.label}`} />;
              default:
                return (
                  <React.Fragment key={item.label}>
                    <MenuItem
                      item={item}
                      locale={router.locale || router.defaultLocale}
                      onClick={(e) => {
                        if (excludedLabesOpenNewWindow.includes(item.label as LeftSideMenuLabels)) {
                          e.preventDefault();
                          window.open(item.link.type === 'link' ? item.link.link : item.link.pageFolder?._url);
                          return;
                        }
                        if (item.label === LeftSideMenuLabels.Accounts) {
                          e.preventDefault();
                          item.children && toggleOpen(item.label);
                          return;
                        }

                        const isOrderGuideSelected = isOrderGuidePage(path);
                        if (isOrderGuideSelected) {
                          if (primaryOrderGuide) {
                            handleSelectOrderGuide({
                              target: { value: primaryOrderGuide },
                            } as React.ChangeEvent<HTMLSelectElement>);
                          }

                          return;
                        }
                        item.children && toggleOpen(item.label);
                      }}
                      isAccountOpened={
                        item.label === LeftSideMenuLabels.Accounts &&
                        openItems.hasOwnProperty(LeftSideMenuLabels.Accounts) &&
                        openItems[LeftSideMenuLabels.Accounts] === true
                      }
                    />
                    {item.children && (
                      <Box as="li">
                        <Collapse in={openItems[item.label]}>
                          <List>
                            {item.children.map((child) => (
                              <ChildrenMenuItem
                                item={child}
                                key={child.label}
                                isSelected={isSelected}
                                onClick={() => {
                                  if (child.label === LeftSideMenuLabels.ShopCategories) {
                                    openShoppingCategories(
                                      megaDrawer.isOpen
                                        ? ShoppingCategoriesOpenProps.DRAWER
                                        : ShoppingCategoriesOpenProps.LEFT_SIDE,
                                    );
                                    return;
                                  }
                                }}
                              />
                            ))}
                          </List>
                        </Collapse>
                      </Box>
                    )}
                  </React.Fragment>
                );
            }
          })}
        </List>

        {/* Accordions */}
        {showAccordionLinks && (
          <Flex mt="auto" display="column" transform={`translateY(-${difference})`} px={6}>
            <Accordion allowToggle mt="50px">
              {accordionLinks.map((accordionLink) => (
                <SideBarAccordionItem title={accordionLink.title} children={accordionLink.children} />
              ))}
            </Accordion>

            {/* LogoutButton */}
            {!isPublicPage && (
              <Button px={3} height={8} variant="ds-filled" mt={4} mb={6} ml={4} fontSize="sm" onClick={handleLogout}>
                {formatMessage({ id: 'action.logout' })}
              </Button>
            )}
          </Flex>
        )}
      </Nav>
    </>
  );
};
