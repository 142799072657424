// @ts-nocheck
import {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { Address } from '@Types/account/Address';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import { calculatedGeocodeDate } from 'composable/components/checkout/utils/calculated-geocode-date';
import { deliveryDateToUtc } from 'composable/components/checkout/utils/delivery-date-to-utc';
import {
  FEATURE_FLAG_DISPLAY_DELIVERY_OPTIONS,
  FEATURE_FLAG_SPLIT_ORDER,
  FEATURE_FLAG_SPLIT_ORDER_EDIT_ORDER,
  GENERIC_TOAST_ERROR_ID,
  TOAST_ICON,
} from 'composable/components/general';
import { SearchInputProvider } from 'composable/components/search-input-context/search-input-context';
import { CHECKOUT_PRIMARY_ORDER, CHECKOUT_SECONDARY_ORDER, IS_SPLIT_ORDER } from 'composable/helpers/constants';
import { DeliveryDatesProps } from 'composable/helpers/hooks';
import { useLocalStorage } from 'composable/helpers/hooks/useLocalStorage';
import { useSplitOrder } from 'composable/helpers/hooks/useSplitOrder';
import { useUserHelpers } from 'composable/helpers/hooks/useUserHelpers';
import { DEFAULT_CURRENCY } from 'helpers/constants/eventTracking';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { useDeliveryOptionsEligibilities } from 'helpers/hooks/useGetDeliveryOptionEligibilities';
import { useGetDeliveryWindows } from 'helpers/hooks/useGetDeliveryWindows';
import { usePurchaseAlgoliaTracking } from 'helpers/hooks/usePurchaseAlgoliaTracking';
import { DeliveryOptionsEligibility } from 'helpers/services/shamrock';
import { DeliveryOptionsEligibilityMap, EligibleOptionsByType } from 'helpers/utils/fetchers';
import { actionSplitCart } from 'hooks/global/use_privateCartGlobal/utils';
import { mountUpdateOrderPayload } from 'utils/orderEdit/mountUpdateOrderPayload';
import { updateCustomerOrderDetail } from 'frontastic/actions/shamrockApi';
import { MarketingMessage } from 'frontastic/tastics/composable/checkout';
import { sleep } from 'frontastic/tastics/composable/edit-order/utils';
import { CheckoutActionType, checkoutReducer, CheckoutState } from './reducer';
import { DEFAULT_SHIPPING_METHOD, KEY_A_LA_CARTE, KEY_PICK_UP } from '../utils/constants';

type CheckoutContextType = {
  placeOrder: () => Promise<void>;
  setComments: (payload: string) => void;
  setDeliveryDate: (payload: Date) => void;
  setSecondaryDate: (payload: Date) => void;
  setPONumber: (payload: string) => void;
  setShippingMethod: (payload: string) => void;
  setDeliveryWindow: (payload: string) => void;
  setDeliveryMinimums: (payload: string) => void;
  setWarehouseAddress: (payload: Partial<Address>) => void;
  setGeocode: (payload: string) => void;
  persistedGroups: any[] | null;
  persistedLineItems: any | null;
  persitedOrderTotal: number | null;
  persistedDeliveryWindows: PersistedDeliveryWindows | null;
  persistedPrimaryOrder: PersistedOrder | null;
  persistedSecondaryOrder: PersistedOrder | null;
  deliveryWindows: ReturnType<typeof useGetDeliveryWindows>['deliveryWindows'];
  deliveryMinimums: ReturnType<typeof useGetDeliveryWindows>['deliveryMinimums'];
  orderGroups: ReturnType<typeof useSplitOrder>['orderGroups'];
  isLoadingDeliveryWindows: ReturnType<typeof useGetDeliveryWindows>['isLoading'];
  eligibility: {
    options: DeliveryOptionsEligibility[];
    byCustomers: DeliveryOptionsEligibilityMap | {};
    byType: EligibleOptionsByType | {};
    isLoading: boolean;
  };
  isSplitOrder: boolean;
  isPlacingOrder: boolean;
  state: CheckoutState;
  openShoppingCategories: (str: string) => void;
  closeShoppingCategories: () => void;
  shoppingCategoriesOpen: ShoppingCategoriesOpenProps;
  orderPlaced: boolean;
  isAcknowledged: boolean;
  setIsAcknowledged: (payload: boolean) => void;
  isAcknowledgedEditMode: boolean;
  setIsAcknowledgedEditMode: (payload: boolean) => void;
  resetCheckboxValue: (editMode: boolean) => void;
  setCutOffDeliveryDates: Dispatch<SetStateAction<DeliveryDatesProps>>;
  setIsValidDeliveryDate: (isValid: boolean) => void;
  isValidDeliveryDate: boolean;
  marketingMessage: MarketingMessage | null;
  marketingMessageEdit: MarketingMessage | null;
  setMarketingMessage: (payload: MarketingMessage | null) => void;
  setMarketingMessageEdit: (payload: MarketingMessage | null) => void;
  updateSplittedOrder: () => Promise<void>;
  isCheckboxError: boolean;
  isCheckboxErrorEditMode: boolean;
  setIsCheckboxError: (payload: boolean) => void;
  setIsCheckboxErrorEditMode: (payload: boolean) => void;
  isCheckboxChecked: (isButtonEnabled: boolean, isEditCheckout: boolean) => boolean;
  responseTime?: number;
  resetAcknowledgedCheckBox: () => void;
};

type PersistedOrder = {
  order: Order;
  selectedWindowDelivery?: string | null;
  warehouseAddress?: Partial<Address> | null;
};

export enum ShoppingCategoriesOpenProps {
  DRAWER = 'drawer',
  LEFT_SIDE = 'leftside',
}

type PersistedDeliveryWindows = {
  deliveryWindows: ReturnType<typeof useGetDeliveryWindows>['deliveryWindows'];
  isLoadingDeliveryWindows: ReturnType<typeof useGetDeliveryWindows>['isLoading'];
};

const CheckoutContext = createContext<CheckoutContextType>(null!);

export const CHECKBOX_ID = 'checkbox-component';
const SCROLL_OFFSET = -500;

const scrollToCheckBox = () => {
  const element = document.getElementById(CHECKBOX_ID);

  if (element) {
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition + SCROLL_OFFSET;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

console.warn('Deprecated provider CheckoutProvider');
export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);

  if (!context) {
    throw new Error('useCheckoutContext must be used within a CheckoutProvider');
  }

  return context;
};

export const GlobalProviderCheckoutProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const persistedGroups = useRef<any[] | null>(null);
  const persistedLineItems = useRef<any | null>(null);
  const persistedOrderTotal = useRef<number | null>(null);
  const persistedDeliveryWindows = useRef<PersistedDeliveryWindows | null>(null);
  const _persistedIsSplitOrder = useRef<boolean>(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [shoppingCategoriesOpen, setShoppingCategoriesOpen] = useState(null);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [isValidDeliveryDate, setIsValidDeliveryDate] = useState(false);

  const user = useGlobal().useUserGlobal;
  const { cart: cartDetails } = useGlobal().useCartGlobal;

  const trackPurchase = usePurchaseAlgoliaTracking(user, user.logout, cartDetails);

  // Checkbox for acknowledging the order information
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isAcknowledgedEditMode, setIsAcknowledgedEditMode] = useState(false);
  const [isCheckboxError, setIsCheckboxError] = useState(false);
  const [isCheckboxErrorEditMode, setIsCheckboxErrorEditMode] = useState(false);

  const isCheckboxChecked = (isButtonEnabled: boolean, isEditCheckout: boolean) => {
    if (!isButtonEnabled) {
      isEditCheckout ? setIsCheckboxErrorEditMode(true) : setIsCheckboxError(true);
      scrollToCheckBox();
      return false;
    }

    return true;
  };

  // Marketing Messages for Edit and Normal Checkout
  const [marketingMessage, setMarketingMessage] = useState<MarketingMessage | null>(null);
  const [marketingMessageEdit, setMarketingMessageEdit] = useState<MarketingMessage | null>(null);

  const resetCheckboxValue = (editMode: boolean) => {
    editMode ? setIsAcknowledgedEditMode(false) : setIsAcknowledged(false);
  };

  const resetAcknowledgedCheckBox = useCallback(() => {
    setIsCheckboxError(false);
    setIsCheckboxErrorEditMode(false);
    setIsAcknowledged(false);
    setIsAcknowledgedEditMode(false);
  }, []);

  //This will be used to display the info in checkout success page
  const [, setPrimaryOrder] = useLocalStorage(CHECKOUT_PRIMARY_ORDER, null);
  const [, setSecondaryOrder] = useLocalStorage(CHECKOUT_SECONDARY_ORDER, null);

  const persistedPrimaryOrder = useRef<PersistedOrder | null>(null);
  const persistedSecondaryOrder = useRef<PersistedOrder | null>(null);

  const [cutOffDeliveryDates, setCutOffDeliveryDates] = useState<DeliveryDatesProps | null>(null);

  const [geocodeDeliveryDate, setGeocodeDeliveryDate] = useState<string | null>(null);
  const softCutoffs = useGlobal().useCutoffsGlobal.softCutoffs;
  const { checkout, removeItem, updateCartDetails: updateCart, cart } = useGlobal().useCartGlobal;

  const [state, dispatch] = useReducer(checkoutReducer, {
    shippingMethod: DEFAULT_SHIPPING_METHOD,
  });
  const {
    editOrder: { isEditOrder, order },
    handleDisableEditOrder,
    setUpdatingOrder,
    persistedSplittedEditOrder,
    state: { activeAccount },
  } = useGlobal().useUserGlobal;

  const lineItemsForSplitOrder = useMemo(() => {
    if (isEditingOrder) {
      return editCart?.lineItems;
    }
    return cart?.lineItems;
  }, [cart?.lineItems, isEditingOrder, editCart]);

  const { orderGroups, isSplitOrder: _isSplitOrder } = useSplitOrder({
    isEditOrder: isEditingOrder,
    lineItems: lineItemsForSplitOrder,
    setCutOffDeliveryDates,
    geocodeDeliveryDate,
    activeAccount,
    extraProductData: [],
    softCutoffs,
  });
  const isSplitOrder =
    _isSplitOrder && (isEditingOrder ? FEATURE_FLAG_SPLIT_ORDER_EDIT_ORDER : FEATURE_FLAG_SPLIT_ORDER);
  const [deliveryMinimums, setDeliveryMinimums] = useState(null);

  // return a vector where the first array contains the products for the first order from orderGroups and the second array contains the products for the second order from orderGroups
  // when it's not split order, the second array will be empty
  const productForDeliveryWindow = useMemo(() => {
    if (orderGroups?.length === 0 && !isEditingOrder) {
      return [[], []];
    }

    if (isEditingOrder && !isSplitOrder) {
      return [
        (editCart?.lineItems || []).map((lineItem) => ({
          productNumber: lineItem.productNumber,
          quantity: lineItem.orderedQuantity || 1,
        })),
        [],
      ];
    }

    if (orderGroups?.length === 0) {
      return [[], []];
    }

    if (isSplitOrder) {
      return orderGroups.map((orderGroup) =>
        orderGroup?.lineItems?.map((lineItem) => ({
          productNumber: lineItem?.productNumber || lineItem?.variant?.sku,
          quantity: lineItem.count || 1,
        })),
      );
    }

    return [
      (cart?.lineItems || []).map((lineItem) => ({
        productNumber: lineItem.variant?.sku,
        quantity: lineItem.count || 1,
      })),
      [],
    ];
  }, [orderGroups, isEditOrder, isSplitOrder, cart?.lineItems, order?.lineItems, geocodeDeliveryDate, softCutoffs]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const primaryOrder = window.localStorage.getItem(CHECKOUT_PRIMARY_ORDER);
      const secondaryOrder = window.localStorage.getItem(CHECKOUT_SECONDARY_ORDER);

      if (primaryOrder && !persistedPrimaryOrder.current) {
        const parsedPrimaryOrder = JSON.parse(primaryOrder);
        setPrimaryOrder(parsedPrimaryOrder);
        persistedPrimaryOrder.current = parsedPrimaryOrder;
      }

      if (secondaryOrder && !persistedSecondaryOrder.current) {
        const parsedSecondaryOrder = JSON.parse(secondaryOrder);
        setSecondaryOrder(parsedSecondaryOrder);
        persistedSecondaryOrder.current = parsedSecondaryOrder;
      }
    }
  }, []);

  //We use this hook here to have prepared the delivery windows for the user once they reach the checkout page
  const {
    currentUserEligibleOptions,
    deliveryOptionsEligibilities,
    eligibleOptionsByCustomers,
    isLoading: isFetchingEligibleOption,
  } = useDeliveryOptionsEligibilities();

  const {
    deliveryWindows,
    isLoading: isLoadingDeliveryWindowsFirstOrder,
    deliveryMinimums: deliveryMinimumsFirstOrder,
    responseTime,
  } = useGetDeliveryWindows(
    productForDeliveryWindow[0],
    isEditingOrder ? editCart?.orderNumber : undefined,
    (currentUserEligibleOptions?.[KEY_PICK_UP] || false) && FEATURE_FLAG_DISPLAY_DELIVERY_OPTIONS,
    (currentUserEligibleOptions?.[KEY_A_LA_CARTE] || false) && FEATURE_FLAG_DISPLAY_DELIVERY_OPTIONS,
    isPlacingOrder,
  );

  const { isLoading: isLoadingDeliveryWindowsSecondOrder, deliveryMinimums: deliveryMinimumsSecondOrder } =
    useGetDeliveryWindows(productForDeliveryWindow[1], undefined, false, false, isPlacingOrder);

  const isLoading = isLoadingDeliveryWindowsFirstOrder || isLoadingDeliveryWindowsSecondOrder;

  useEffect(() => {
    const nextDeliveryMinimums = [...(deliveryMinimumsFirstOrder || []), ...(deliveryMinimumsSecondOrder || [])];

    if (nextDeliveryMinimums && JSON.stringify(nextDeliveryMinimums) !== JSON.stringify(deliveryMinimums)) {
      setDeliveryMinimums(nextDeliveryMinimums);
    }
  }, [deliveryMinimumsFirstOrder, deliveryMinimumsSecondOrder]);

  const { getBillingAddress, getShippingAddress, getCustomerEmail, getCustomFields } = useUserHelpers();

  const setComments = (payload: string) => dispatch({ type: CheckoutActionType.SET_COMMENTS, payload });

  const setDeliveryDate = (payload: Date) => dispatch({ type: CheckoutActionType.SET_DELIVERY_DATE, payload });

  const setSecondaryDate = (payload: Date) => dispatch({ type: CheckoutActionType.SET_SECONDARY_DATE, payload });

  const setPONumber = (payload: string) => dispatch({ type: CheckoutActionType.SET_PO_NUMBER, payload });

  const setShippingMethod = (payload: string) => dispatch({ type: CheckoutActionType.SET_SHIPPING_METHOD, payload });

  const setDeliveryWindow = (payload: string) => dispatch({ type: CheckoutActionType.SET_DELIVERY_WINDOW, payload });

  const setGeocode = (payload: string) => dispatch({ type: CheckoutActionType.SET_GEO_CODE, payload });

  const setWarehouseAddress = (payload: Partial<Address>) =>
    dispatch({ type: CheckoutActionType.SET_WAREHOUSE_ADDRESS, payload });

  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });

  const updateSplittedOrder = async () => {
    window.localStorage.setItem(IS_SPLIT_ORDER, 'true');

    toggleOrderUpdatingState(true);

    try {
      const regularOrder = (orderGroups[0].lineItems as OrderDetailLineItem[]).map<LineItem>((item) => ({
        variant: {
          sku: item.productNumber,
        },
        count: item.orderedQuantity,
        price: {
          centAmount: item.originalPrice,
          currencyCode: DEFAULT_CURRENCY,
        },
      }));

      const splitOrder = (orderGroups[1].lineItems as OrderDetailLineItem[]).map<LineItem>((item) => ({
        variant: {
          sku: item.productNumber,
        },
        count: item.orderedQuantity,
        price: {
          centAmount: item.originalPrice,
          currencyCode: DEFAULT_CURRENCY,
        },
      }));

      const cartDetailsMainOrder = {
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        custom: getCustomFields({
          deliveryDate: state.deliveryDate,
          specialHandlingInstructions: state.comments,
          selectedShippingMethod: state.shippingMethod,
          geocode: state.geocode,
        }),
      };

      const cartDetailsSplitOrder = {
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        custom: getCustomFields({
          deliveryDate: state.secondaryDate,
          specialHandlingInstructions: state.comments,
          selectedShippingMethod: DEFAULT_SHIPPING_METHOD,
          geocode: state.geocode,
        }),
      };

      const checkoutResponse = await checkout(
        {
          lineItems: regularOrder,
          deliveryType: state.shippingMethod,
          deliveryDate: state.deliveryDate,
          geocode: state.geocode,
          poNumber: state?.poNumber,
          instructions: state?.comments,
        },
        {
          lineItems: splitOrder,
          deliveryType: DEFAULT_SHIPPING_METHOD,
          deliveryDate: state.secondaryDate,
          geocode: state.geocode,
          poNumber: state?.poNumber,
          instructions: state?.comments,
        },
        { orderDetails: cartDetailsMainOrder, splitOrderDetails: cartDetailsSplitOrder },
      );

      if (!checkoutResponse?.order) {
        throw new Error('Error API: order on splitCart');
      }

      if (!checkoutResponse?.splitOrder) {
        throw new Error('Error API: splitCart');
      }

      const resUpdateCart = await updateCart({
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        custom: getCustomFields({
          deliveryDate: state.secondaryDate,
          specialHandlingInstructions: editCart.handlingInstructions,
          selectedShippingMethod: DEFAULT_SHIPPING_METHOD,
          geocode: editCart.geocode ?? '',
        }),
      });

      if (!resUpdateCart?.cartId) {
        throw new Error('Error API: updateCart');
      }

      if (checkoutResponse?.splitOrder) {
        updateSplitOrder(editCart.orderNumber, checkoutResponse?.splitOrder);
      } else {
        throw new Error('Error API: checkout');
      }

      const payload = mountUpdateOrderPayload({
        ...editCart,
        lineItems: orderGroups[0].lineItems as OrderDetailLineItem[],
      });

      await updateCustomerOrderDetail(payload, toast, formatMessage({ id: 'app.generic.error' }));
    } catch (error) {
      // If API is not successful then the user stays on the
      // checkout page and toast error is displayed for 5 seconds

      toggleOrderUpdatingState(false);

      console.error('Error API: updateCustomerOrderDetail', error);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'editOrder.checkout.toast.error' }),
          icon: TOAST_ICON.error,
        });
      }
      return;
    }

    // delay the redirect to allow the API to complete the update
    await sleep(3000);

    // If API is successful, user is taken back to the Order details
    //  with toast success message is displayed for 5 seconds
    router.push(`${routes.ORDER_DETAIL_PAGE}${editCart.customerNumber}/${editCart.orderNumber}`);

    // delay the toast to allow the page to redirect
    await sleep(1000);
    toast({
      duration: 5000,
      status: 'success',
      title: formatMessage({ id: 'editOrder.checkout.toast.success' }),
      icon: TOAST_ICON.success,
    });
    disableOrderEditing();

    setTimeout(() => {
      window.localStorage.removeItem(IS_SPLIT_ORDER);
    }, 1000);
  };

  const placeOrder = async () => {
    try {
      setIsPlacingOrder(true);
      setOrderPlaced(true);
      _persistedIsSplitOrder.current = isSplitOrder;
      persistedLineItems.current = cart?.lineItems ?? [];
      persistedGroups.current = [...orderGroups];
      persistedOrderTotal.current = cart?.sum?.centAmount;
      persistedDeliveryWindows.current = {
        deliveryWindows: { ...deliveryWindows },
        isLoadingDeliveryWindows: isLoading,
      };
      if (!cart.lineItems?.length) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'editOrder.cart.empty' }),
          icon: TOAST_ICON.error,
        });
        return;
      }

      trackPurchase();
      _persistedIsSplitOrder.current ? await placeSplittedOrder() : await placeSingleOrder();
    } catch (e) {
      console.error('Error API call: placeOrder', e);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'app.generic.error' }),
          id: GENERIC_TOAST_ERROR_ID,
          icon: TOAST_ICON.error,
        });
      }
    } finally {
      setIsPlacingOrder(false);
      setTimeout(() => {
        _persistedIsSplitOrder.current = false;
        persistedLineItems.current = null;
        persistedGroups.current = null;
        persistedOrderTotal.current = null;
        persistedDeliveryWindows.current = null;
        setOrderPlaced(false);
      }, 3000);
    }
  };

  const placeSingleOrder = async () => {
    persistedPrimaryOrder.current = null;
    persistedSecondaryOrder.current = null;
    setPrimaryOrder(null);
    setSecondaryOrder(null);

    try {
      const resCheckout = await checkout({
        poNumber: state.poNumber,
        shippingAddress: getShippingAddress(),
        billingAddress: getBillingAddress(),
        account: getCustomerEmail(),
        customFields: getCustomFields({
          deliveryDate: state.deliveryDate,
          specialHandlingInstructions: state.comments,
          selectedShippingMethod: state.shippingMethod,
          geocode: state.geocode,
        }),
      });

      if (resCheckout?.order) {
        setPONumber('');
        setComments('');
        const regularResponse = { order: resCheckout.order };
        setPrimaryOrder(regularResponse);
        persistedPrimaryOrder.current = regularResponse;
        router.push(routes.CHECKOUT_SUCCESS);
      }
    } catch (error) {
      console.error('Error API call: placeSingleOrder', error);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'app.generic.error' }),
          id: GENERIC_TOAST_ERROR_ID,
          icon: TOAST_ICON.error,
        });
      }
    }
  };

  const placeSplittedOrder = async () => {
    persistedPrimaryOrder.current = null;
    persistedSecondaryOrder.current = null;
    setPrimaryOrder(null);
    setSecondaryOrder(null);

    try {
      window.localStorage.setItem(IS_SPLIT_ORDER, 'true');
      // Split order in two carts
      // For the first cart, the line items from the second cart are deleted, then proceed to checkout
      const secondaryCartItems = orderGroups[1].lineItems as LineItem[];

      const commonFields = {
        shipping: getShippingAddress(),
        billing: getBillingAddress(),
        account: getCustomerEmail(),
        poNumber: state.poNumber,
      };

      const mainOrderCustomFields = getCustomFields({
        deliveryDate: state.deliveryDate,
        specialHandlingInstructions: state.comments,
        selectedShippingMethod: state.shippingMethod,
        geocode: state.geocode,
      });

      const splitOrderCustomFields = getCustomFields({
        deliveryDate: state.secondaryDate,
        specialHandlingInstructions: state.comments,
        selectedShippingMethod: DEFAULT_SHIPPING_METHOD,
        geocode: state.geocode,
      });

      const splitCheckoutResponse = await actionSplitCart({
        ...commonFields,
        cartId: cart?.cartId,
        lineItems: secondaryCartItems,
        custom: splitOrderCustomFields,
        deliveryType: DEFAULT_SHIPPING_METHOD,
        deliveryDate: state.secondaryDate,
        geocode: state.geocode,
        instructions: state.comments,
        updateOriginalCart: {
          ...commonFields,
          customFields: mainOrderCustomFields,
        },
      });

      if (splitCheckoutResponse?.mainOrder) {
        const regularResponse = { order: splitCheckoutResponse.mainOrder };
        persistedPrimaryOrder.current = regularResponse;
        setPrimaryOrder(regularResponse);
      }

      if (splitCheckoutResponse?.splitOrder) {
        const splitResponse = { order: splitCheckoutResponse.splitOrder };
        persistedSecondaryOrder.current = splitResponse;
        setSecondaryOrder(splitResponse);
      }

      setPONumber('');
      setComments('');
      router.push(routes.CHECKOUT_SUCCESS);

      setTimeout(() => {
        window.localStorage.removeItem(IS_SPLIT_ORDER);
      }, 1000);
    } catch (error) {
      console.error('Error API call: placeSplittedOrder', error);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'app.generic.error' }),
          id: GENERIC_TOAST_ERROR_ID,
          icon: TOAST_ICON.error,
        });
      }
    }
  };

  const openShoppingCategories = useCallback((str: ShoppingCategoriesOpenProps) => {
    setShoppingCategoriesOpen(str);
  }, []);

  const closeShoppingCategories = useCallback(() => {
    setShoppingCategoriesOpen(null);
  }, []);

  return (
    <CheckoutContext.Provider
      value={{
        placeOrder,
        setComments,
        setDeliveryDate,
        setSecondaryDate,
        setPONumber,
        setShippingMethod,
        setDeliveryWindow,
        setDeliveryMinimums,
        setWarehouseAddress,
        setGeocode,
        orderPlaced,
        persistedGroups: persistedGroups.current,
        persistedLineItems: persistedLineItems.current,
        persitedOrderTotal: persistedOrderTotal.current,
        persistedPrimaryOrder: persistedPrimaryOrder.current,
        persistedSecondaryOrder: persistedSecondaryOrder.current,
        persistedDeliveryWindows: persistedDeliveryWindows.current,
        deliveryWindows,
        eligibility: {
          options: deliveryOptionsEligibilities,
          byCustomers: eligibleOptionsByCustomers,
          byType: currentUserEligibleOptions,
          isLoading: isFetchingEligibleOption,
        },
        isLoadingDeliveryWindows: isLoading,
        deliveryMinimums,
        isPlacingOrder,
        orderGroups: orderGroups,
        isSplitOrder: isSplitOrder || _persistedIsSplitOrder.current,
        state,
        openShoppingCategories,
        closeShoppingCategories,
        shoppingCategoriesOpen,
        isAcknowledged,
        setIsAcknowledged,
        isAcknowledgedEditMode,
        setIsAcknowledgedEditMode,
        resetCheckboxValue,
        setCutOffDeliveryDates,
        isValidDeliveryDate,
        setIsValidDeliveryDate,
        marketingMessage,
        marketingMessageEdit,
        setMarketingMessage,
        setMarketingMessageEdit,
        updateSplittedOrder,
        isCheckboxError,
        isCheckboxErrorEditMode,
        setIsCheckboxError,
        setIsCheckboxErrorEditMode,
        isCheckboxChecked,
        responseTime,
        resetAcknowledgedCheckBox,
      }}
    >
      <SearchInputProvider>{children}</SearchInputProvider>
    </CheckoutContext.Provider>
  );
};
